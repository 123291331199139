import React from 'react'
import Layout from '../components/layout'
import services_hero from '../assets/illustrations/stylish/website-designing-1768780-0.svg';
import { Link } from 'gatsby';
import services from '../data/services.json';
import services_list from '../data/services_list.json';
import Seo from '../components/seo';

export default function PrivacyPolicy() {
    return (
        <Layout>
            <Seo title="Privacy Policy" />
            <div className="bg-white py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="head-font font-bold text-3xl 4xl:text-4xl text-darkblue-tf">PRIVACY POLICY</h1>
                        {/* <p className="text-md font-thin text-gray-700">QAPPS & QMOBILE</p> */}
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="text-lg 4xl:text-2xl text-gray-600">
                            This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally Identifiable Information’
                            (PII) is being used online. PII, as described in US privacy law and information security,
                            is information that can be used on its own or with other information to identify, contact, or locate a single person,
                            or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of
                            how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                        </p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="text-3xl 4xl:text-4xl head-font font-bold  text-darkblue-tf">FAQ</h1>
                        <p className="text-md 4xl:text-2xl font-thin text-gray-700 uppercase">Frequently asked questions</p>
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="font-bold 4xl:text-2xl text-lg text-darkblue-tf">
                            What personal information do we collect from the people that visit our blog, website or app?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, Mobile Number or other details to help you with your experience.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            When do we collect information?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            We collect information from you when you register on our site, fill out a form or enter information on our site.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            How do we use your information?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
                        <p className="text-lg text-gray-600 mt-2 ml-3">• To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</p>
                        <p className="text-lg text-gray-600 mt-1 ml-3">• To allow us to better service you in responding to your customer service requests.</p>
                        <p className="text-lg text-gray-600 mt-1 ml-3">• To send periodic emails regarding your order or other products and services.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            How long will we keep information about you?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            We will keep your personal information and sensitive personal information for such period/s as may be appropriate or necessary for the reasonable needs of the business or as may be required or permitted by applicable laws and/or regulations.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            How do we protect your information?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            At Timefree, we take the security of your personal information seriously and employ multiple layers of protection to safeguard your data. Our security measures include:<br />

                            <p className="text-lg text-gray-600 mt-2 ml-3">• <strong>Regular Vulnerability and Malware Scanning</strong>: We conduct regular vulnerability scans to identify potential security weaknesses and take immediate action to mitigate risks. Additionally, we perform routine malware scans to detect and prevent any malicious activity on our systems.</p>
                            <p className="text-lg text-gray-600 mt-2 ml-3">• <strong>Secured Networks</strong>: Your personal data is stored on secured networks, accessible only to authorized personnel who have special access rights. These individuals are required to keep your information confidential and are regularly trained in data protection practices.</p>
                            <p className="text-lg text-gray-600 mt-2 ml-3">• <strong>Encryption</strong>: All sensitive and credit information you provide is encrypted using Secure Socket Layer (SSL) technology. This ensures that your data remains secure during transmission between your browser and our servers.</p>
                            <p className="text-lg text-gray-600 mt-2 ml-3">• <strong>Comprehensive Security Measures</strong>: We implement a variety of security controls when you enter, submit, or access your information. These include strong password policies, firewalls, and secure authentication methods to protect against unauthorized access and ensure the integrity of your data.</p>
                            <p className="text-lg text-gray-600 mt-2 ml-3">• <strong>Transaction Processing</strong>: All transactions are securely processed through a trusted gateway provider. We do not store or process sensitive payment information on our servers, minimizing the risk of exposure.</p>
                            <br />
                            <p className="text-lg text-gray-600 mt-2 ml-3">While no security system is impenetrable, we continuously update and enhance our security practices to stay ahead of potential threats and ensure the safety of your personal information.</p>

                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            Do we use "cookies"?
                        </p>
                        <p className="text-lg text-gray-600 mt-1">
                            We do not use cookies for tracking purposes.<br />
                            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser’s Help Menu to learn the correct way to modify your cookies.
                            <br /><br />
                            If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.that make your site experience more efficient and may not function properly.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            Third-party links
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            We do not include or offer third-party products or services on our website.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            How does our site handle Do Not Track signals?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            Does our site allow third-party behavioral tracking?
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            It’s also important to note that we do not allow third-party behavioral tracking
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            We collect your email address in order to:
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            Send information, respond to inquiries, and/or other requests or questions
                        </p>
                    </div>
                </div>
            </div>

            <div id="data_requests" className="anchor bg-white py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="text-3xl 4xl:text-4xl head-font font-bold  text-darkblue-tf uppercase">Data Privacy</h1>
                        {/* <p className="text-lg font-thin text-gray-700">QAPPS & QMOBILE</p> */}
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="text-lg 4xl:text-2xl text-gray-600">
                            The Data Privacy Act of 2012 and its implementing rules and regulations prescribe the level of security required in preserving the confidentiality, integrity and availability of personal information. In compliance, our privacy policy lays down safety measures and safeguards that are reasonable under the circumstances but in no case lesser than the degree of security required under the law.
                        </p>
                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            Your Rights
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            If you would like to exercise your rights (as listed below) to or have any concerns about our handling of your personal information and sensitive personal information, please contact our Data Privacy Officer.
                        </p>
                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            Rights you can exercise
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            • request a copy of your information which you supplied to us;<br />
                            • request us to correct or delete your information;<br />
                            • object to us processing your information;<br />
                            • inform us of any clarification and/or complaint you have in relation to the personal information and sensitive personal information being collected, processed, shared, and/or purged;<br />
                            • lodge a complaint with the National Privacy Commission
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-gray-600 mt-6">
                            For requests for deletion of your information, please get in touch with our Data Privacy Office <Link to="#contact" className="text-blue-tf">here.</Link>
                        </p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="text-3xl 4xl:text-4xl head-font font-bold  text-darkblue-tf uppercase">PROTECTION ACTS</h1>
                        {/* <p className="text-lg font-thin text-gray-700">QAPPS & QMOBILE</p> */}
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf">
                            California Online Privacy Protection Act
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.
                            <br />See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            According to CalOPPA, we agree to the following:
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            Users can visit our site anonymously.Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.
                            <br /><br />Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above.
                            <br /><br />You will be notified of any Privacy Policy changes:
                            <br />• On our Privacy Policy Page
                            <br /><br />Can change your personal information:
                            <br />• By logging in to your account
                        </p>


                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            COPPA (Children Online Privacy Protection Act)
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            When it comes to the collection of personal information from children under the age of 13 years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.
                            <br />We do not specifically market to children under the age of 13 years old.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            Fair Information Practices
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
                        </p>

                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            We will notify the users via in-site notification<br />
                            • Within 7 business days<br />
                            We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
                        </p>


                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            CAN SPAM Act
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
                        </p>


                        <p className="font-bold text-lg 4xl:text-2xl text-darkblue-tf mt-6">
                            To be in accordance with CANSPAM, we agree to the following:
                        </p>
                        <p className="text-lg 4xl:text-2xl text-gray-600 mt-1">
                            • Not use false or misleading subjects or email addresses.
                            <br />• Identify the message as an advertisement in some reasonable way.
                            <br />• Include the physical address of our business or site headquarters.
                            <br />• Monitor third-party email marketing services for compliance, if one is used.
                            <br />• Honor opt-out/unsubscribe requests quickly.
                            <br />• Allow users to unsubscribe by using the link at the bottom of each email.
                        </p>
                    </div>
                </div>
            </div>

            <div id="contact" className="bg-white py-8">
                <div className="flex flex-wrap m-4 lg:m-16 gap-x-0 justify-center">
                    <div className="w-full md:w-1/4">
                        <h1 className="text-3xl 4xl:text-4xl head-font font-bold text-darkblue-tf uppercase">Contact</h1>
                        <p className="text-md 4xl:text-2xl font-thin text-gray-700 uppercase">Requests and/or Complaints</p>
                    </div>
                    <div className="w-full md:w-1/2 mt-4 lg:mt-0">
                        <p className="text-lg 4xl:text-2xl text-gray-600">
                            For questions about how we use your information or requests for deletion of your information, you may get in touch with us through our&nbsp;
                            <Link to="/contact" className="underline font-bold text-darkblue-tf">CONTACT PAGE</Link> or directly via the channels below:
                        </p>

                        <p className="text-lg 4xl:text-2xl text-gray-600">
                            <br /><strong className="text-darkblue-tf text-xl 4xl:text-3xl">TimeFree Innovations, Inc.</strong>
                            <br />3B Trans-Phil House, Bagtikan St. corner Chino Roces Avenue
                            <br />Makati, Metro Manila 1203
                            <br />Philippines
                            <br />dpo@timefree.ph
                            <br />(+632) 7001 1074 | (+632) 8692 3890
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
